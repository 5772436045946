import React from 'react';
import Icon from "../../../../../common/components/atoms/Icon/Icon";
import BoostCard from "../../../../../common/components/organisms/BoostCard/BoostCard";


const BoostInvite = ({
    className = '',
    isLocked = false,
    ...props
}) => {
    const componentName = 'boost-daily-check-in';

    // dummy data
    const cardData = [
        {
            "cost": "0",
            "improvement_key": null,
            "improvement_value": null,
            "locked": !(props.upgrades?.['day'] ?? true),
            "tag_key": "mega",
            "tag_value": "$$$",
            "type": "$10000 Bonus",
            "label": props.upgrades?.['ub3'] ? "Claimed" : "Get",
        }
    ]

    return (
        <section
            className={
                `boosts-container
                ${componentName}
                ${className}
            `}
        >
            <div className="header">
                <p>
                    <Icon name="refresh" />
                    <b className="small">Daily Check In</b>
                </p>

                <p className="text-center">
                    <small>Refreshes every 24 hours. Return daily to claim your daily check in bonus</small>
                </p>
            </div>

            {cardData.map((item, index) => (
                <BoostCard
                    key={index}
                    cardData={item}
                    clicked={(d) => {
                        props?.buyUpgrade('check');
                    }}
                />
            ))}
        </section>
    );
};

export default BoostInvite;

