import React from 'react';
import IconHeader from './../../../../assets/images/intro-modal-0.png';
import MotionSlideInFromRight from "../../../../common/components/_animation/MotionSlideInFromRight";
import MotionFadeInUp from "../../../../common/components/_animation/MotionFadeInUp";


const IntroWelcome = (props) => {
    return (
        <>
            <MotionFadeInUp className="icon-container">
                <img
                    className="stage-icon"
                    src={IconHeader}
                    alt="Boosts button"
                />
            </MotionFadeInUp>

            <MotionSlideInFromRight>
                <h1 className="modal-content-header">Welcome to Season 4</h1>

                <p>Introducing new features including Daily Check In, Timed Turbo and Level Up, check out the help section for more info. The scores are reset, race to the top for your share of $1M $SLING!</p>
            </MotionSlideInFromRight>
        </>
    );
};

export default IntroWelcome;
