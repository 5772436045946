import React from 'react';
import './updates.scss';
import updatesData from "../../../../data/updates.json";
import Icon from "../../../../common/components/atoms/Icon/Icon";


const Updates = () => {
    return (
        <section className="updates">
            <h1 className="modal-content-header">
                <Icon name="info"/>
                Latest Updates
            </h1>

            {updatesData.map((update, index) => (
                <article className="update-container">
                    <h2 className="update-title">{update.title}</h2>
                    <p className="update-date">{update.date}</p>

                    <div
                        className="update-description"
                        dangerouslySetInnerHTML={{__html: update.description}}
                    />
                </article>
            ))}
        </section>
    );
};

export default Updates;
