import { ReadyState } from "react-use-websocket";
import { httpGet } from "../data/httpGet";

class Game {
    static actions = {
        TOGGLE: 't', // toggle a modal on the game
        RE_AUTH: 'a', // tell client to reauth
        LOGGED_OUT: 'A', // tell client to log out
        INTRO: 'intro', // tell client to show the info
        ADD: '+', // add to score
        SEED: 'S',
        SCORE: 's', // set score
        IDEA: 'i', // add idea
        DATA: 'd', // set data (when logged in)
        OFFLINE_EARNINGS: 'oe' // offline earnings modal trigger
    }

    static processAction(
        {
            readyState,
            lastJsonMessage,
            setIsShowing,
            setIsModalOpen,
            setScore,
            setFactoryLocked,
            setStudioLocked,
            addIdea,
            setFactoryScore,
            setFactoryName,
            setUpgrades,
            setSeed,
            setTurbo,
            setInvite,
            setOfflineEarnings,
        }
    ) {
        const actions = Game.actions;
        if (readyState === ReadyState.OPEN && lastJsonMessage !== null) {
            if (lastJsonMessage?.a) {
                switch (lastJsonMessage.a) {
                    case actions.RE_AUTH:
                        console.log('WS: re-authenticate');
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const mid = urlParams.get('mid');
                        const f = urlParams.get('f');
                        if (f) {
                            localStorage.setItem("fid", f);
                        }
                        window.location.replace(process.env.REACT_APP_LOGIN_URL + (mid ? '?mid=' + mid : ''));
                        break;
                    case actions.OFFLINE_EARNINGS:
                        // offline-earnings
                        setOfflineEarnings(lastJsonMessage?.d?.oe ?? 0);
                        setIsShowing('offline-earnings');
                        break;
                    case actions.LOGGED_OUT:
                        console.log('WS: loggedout');
                        window.location.replace("/signed-out");
                        break;
                    case actions.SEED:
                        console.log('Seed: ', lastJsonMessage?.d);
                        setSeed(lastJsonMessage?.d?.s);
                        break;
                    case actions.INTRO:
                        console.log('WS: show intro');
                        window.location.replace("/intro");
                        break;
                    case actions.TOGGLE:
                        console.log('WS: toggle', lastJsonMessage?.d);
                        if (lastJsonMessage?.d) {
                            setIsShowing(lastJsonMessage.d);
                            if (lastJsonMessage.d !== 'home') {
                                setIsModalOpen(true)
                            }
                        }
                        break;
                    case actions.DATA:
                        console.log('WS: data', lastJsonMessage?.d);
                        setScore((lastJsonMessage?.d?.s) ?? 0);
                        setFactoryName((lastJsonMessage?.d?.f?.[0]) ?? 0);
                        setFactoryScore((lastJsonMessage?.d?.f?.[1]) ?? 0);
                        setUpgrades((lastJsonMessage?.d?.u) ?? []);
                        if (lastJsonMessage?.d?.b?.i) {
                            setFactoryLocked(false);
                        }
                        if (lastJsonMessage?.d?.b?.s) {
                            setStudioLocked(false);
                        }
                        setTurbo(lastJsonMessage?.d?.t ?? [0, 0, 0]);
                        setInvite([
                            lastJsonMessage?.d?.i ?? 0,
                            lastJsonMessage?.d?.un ?? '',
                        ]);
                        setSeed({
                            // seed
                            s: lastJsonMessage?.d?.gs?.s,
                            t: lastJsonMessage?.d?.gs?.t,

                            // wait times
                            wi: lastJsonMessage?.d?.w?.i,
                            ws: lastJsonMessage?.d?.w?.s,

                            // loot table
                            l: lastJsonMessage?.d?.l,

                            // success chance
                            ci: lastJsonMessage?.d?.c?.i,
                            cs: lastJsonMessage?.d?.c?.s,
                        });
                        break;
                    case actions.SCORE:
                        console.log("WS: Set score", lastJsonMessage?.d);
                        setScore(lastJsonMessage?.d ?? 0);
                        break;
                    case actions.ADD:
                        console.log("WS: + score", lastJsonMessage?.d);
                        // not set here. We will allow the update to
                        setScore(score  => {return score + (lastJsonMessage?.d ?? 0)});
                        break;
                    case actions.IDEA:
                        console.log("WS: Add idea", lastJsonMessage?.d);
                        if (lastJsonMessage?.d !== null) {
                            addIdea(lastJsonMessage?.d)
                        } else {
                            setFactoryLocked(false);
                        }
                        break;
                    case "ka":
                        console.log("WS: Keep alive");
                        break;
                    default:
                        console.log('WS: Unknown', lastJsonMessage);
                        break;
                }
                // check for updates in message
                if (lastJsonMessage?.u) { // factory score

                    if (lastJsonMessage.u?.u) { // user upgrades
                        console.log('UU update', lastJsonMessage.u.u);
                        setUpgrades(lastJsonMessage.u.u);
                    }
                    if (lastJsonMessage.u?.f) { // factory score
                        console.log('FS update', lastJsonMessage.u.f);
                        setFactoryScore(lastJsonMessage.u.f);
                    }
                    if (lastJsonMessage.u?.s) { // user score
                        console.log('US update', lastJsonMessage.u.s);
                        setScore(lastJsonMessage.u.s);
                    }

                }
            }
        }
    }

    static joinFactory(factory, showGameScreen, factoryScreenError) {
        console.log('Joined:', factory);
        const fid = localStorage.getItem('fid');
        httpGet(process.env.REACT_APP_WS_URL + '/pick/' + factory + (fid ? '?fid=' + fid : ''))
            .then(() => {
                window.location.replace("/");
            })
    }
}
export default Game;
