import React from 'react';
import "./game-blocked.css";
import Modal from '../molecules/Modal/Modal';


const GameBlocked = () => {
    return (
        <div className="game-blocked">
            <Modal>
                <img
                    className="logo"
                    alt="Game Factory logo"
                    src="/logo-combined-season.webp"
                />

                <div className="inner-content">
                    <h1>Thanks everyone!</h1>

                    <p>
                        <b>Thank you to everyone who participated in the last season of Game Factory! An incredible turnout
                            with thousands of players joining the fun.</b>
                    </p>

                    <p>
                        Slingshot is thrilled to announce that Season 4 will be launching shortly with new
                        features. Remember to participate in all seasons for a bigger slice of the total $1,000,000 in
                        prizes.
                    </p>

                    <p>
                        <b>
                            Stay tuned for more details, you don’t want to miss this!
                        </b>
                    </p>
                </div>
            </Modal>
        </div>
    );
};

export default GameBlocked;
