import React from 'react';
import './belt-info.scss';

const BeltInfo = ({
    level,
    percentage,
    ...props
}) => {
    const componentName = 'belt-info';

    return (
        <aside className={componentName}>
            <p>Level</p>

            <div className="info-screen">
                {level ? level : ''}
            </div>

            {percentage && (
                <p>+{percentage}%</p>
            )}
        </aside>
    );
};

export default BeltInfo;
