import React, {useEffect, useState} from 'react';
import './app-nav.scss';
import NavItem from "../../molecules/NavItem/NavItem";
import Icon from "../../atoms/Icon/Icon";
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";
import { ordinal_suffix_of } from "../../../utils/maths";

const AppNav = ({
    className,
    forceActive,
    ...props
}) => {
    const componentName = 'app-nav';
    const [activeItem, setActiveItem] = useState(forceActive);
    const {isPending, isError, data} = useGetLeaderboard(props.username);

    const handleNavClick = (clicked) => {
        setActiveItem(clicked === activeItem ? '' : clicked);
        props.clicked(clicked);
    };

    //  sub content "home" in the game can trigger other navs so need to listen to those changes
    useEffect(() => {
        setActiveItem(forceActive);
    }, [forceActive]);

    return (
        <aside
            className={`${componentName} ${className}`}
        >
            <div className="app-nav-header">
                <ul className="stats">
                    <li>
                        <span className="caption">
                            Total Revenue Generated
                        </span>

                        ${props?.score.toLocaleString()}
                    </li>
                    <li>
                         <span className="caption">
                             <Icon name="trophy-outline" />
                             Rank
                         </span>
                        {(isPending ? '' : ordinal_suffix_of(data.player[4]))}
                    </li>
                </ul>
            </div>

            <div className="app-nav-ctas">
                <NavItem
                    isActive={activeItem === 'home'}
                    clicked={() => handleNavClick('home')}>
                    <Icon name="home" />
                    Home
                </NavItem>
                <NavItem
                    isActive={activeItem === 'leaderboard'}
                    clicked={() => handleNavClick('leaderboard')}>
                    <Icon name="trophy" />
                    Board
                </NavItem>
                <NavItem
                    isActive={activeItem === ''}
                    clicked={() => handleNavClick('')}
                >
                    <Icon name="factory" />
                    Factory
                </NavItem>
                <NavItem
                    isActive={activeItem === 'boosts'}
                    className="boost-energy-cta"
                    clicked={() => handleNavClick('boosts')}
                >
                    <Icon name="fire" />
                    Boost
                </NavItem>
            </div>
        </aside>
    );
};

export default AppNav;
