import React, {useEffect, useState} from 'react';
import './home.scss';
import updatesData from '../../../../data/updates.json';
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";
import LogoStandaloneSeason from './../../../../assets/images/logo-standalone-season.webp';
import Unicorn from "../../../../assets/images/unicorn.png";
import Calander from "../../../../assets/images/game-assets/home/calander.png";
import Plus from "../../../../assets/images/game-assets/home/plus.png";
import Icon from "../../../../common/components/atoms/Icon/Icon";
import { ordinal_suffix_of } from "../../../../common/utils/maths";


const GameHome = (props) => {
    const {
        data
    } = useGetLeaderboard(props.invite[1]);

    const showFactory = () => {
        props.showFactory();
    };

    const showContent = (toShow) => {
        props.toggleContent(toShow);
    };

    const [copied, setCopied] = useState(false);

    const copyToClipboard = async () => {
        if (navigator?.clipboard?.writeText) {
            await navigator.clipboard.writeText('https://gf.slingshotdao.com?f=' + (props?.invite[1] ?? ''));
            setCopied(true);

            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }

    return (
        <main className="game-homescreen">
            <header className="game-homescreen-header">
                <div>
                    <img
                        className="logo"
                        src={LogoStandaloneSeason}
                        alt="Game Factory season logo"
                    />

                    <p className="rank">
                        <Icon name="trophy-outline"/>
                        Rank {ordinal_suffix_of(data?.player[4]) ?? ''}
                    </p>
                </div>

                <div className="homescreen-my-revenue">
                    <p>Total Generated Revenue</p>
                    <h3 className="score">${props?.score.toLocaleString() ?? 0}</h3>
                    <button
                        className="btn small"
                        onMouseDown={showFactory}
                    >
                        Go to my Factory
                    </button>
                </div>
            </header>


            <section className="card asset-left daily-check-in">
                <p>
                    <b>Daily check in</b>
                </p>
                <p className="small">Earn and extra $10k revenue, every day!</p>
                <button
                    className="btn small"
                    onMouseDown={() => showContent('boosts')}
                >
                    Check in Now
                </button>
                <img
                    className="calender"
                    src={Calander}
                    alt="Calander"
                />
            </section>


            <section className="card asset-left">
                <p>
                    <b>Invite Friends To Earn More</b>
                </p>
                <p className="small">Invite friends to Game Factory and unlock huge revenue boosts!</p>

                <div className="copy-cta-container">
                    <button
                        className="btn small"
                        onMouseDown={() => copyToClipboard()}
                    >
                        Copy Link
                    </button>

                    {copied && (
                        <aside className="copy-message">
                            <Icon name="check" />
                            <p className="small">Copied</p>
                        </aside>
                    )}
                </div>
                <img
                    className="plus"
                    src={Plus}
                    alt="Plus sign"
                />
            </section>


            <section className="card updates">
                <div className="ticker">
                    <p className="ticker-content">
                        <span>LATEST UPDATES</span>
                        <span>LATEST UPDATES</span>
                    </p>
                    <p className="ticker-content">
                        <span>LATEST UPDATES</span>
                        <span>LATEST UPDATES</span>
                    </p>
                </div>

                <p>
                    <b>{updatesData[0].title}</b>
                    <br/>
                    <small>{updatesData[0].date}</small>
                </p>
                <div
                    className="update-description"
                    dangerouslySetInnerHTML={{__html: updatesData[0].description}}
                />
                <button
                    className="btn small"
                    onMouseDown={() => showContent('updates')}
                >
                    View Updates
                </button>
            </section>


            <section className="card asset-left">
                <p>
                    <b>Boost to win big!</b>
                </p>
                <p className="small">Maximise your revenue with boosts!</p>
                <button
                    className="btn small"
                    onMouseDown={() => showContent('boosts')}
                >
                    Unlock Boosts
                </button>
                <img
                    className="unicorn"
                    src={Unicorn}
                    alt="Unicorn avatar"
                />
            </section>

            <section className="card">
                <p>
                    <b>Give Feedback</b>
                </p>
                <p className="small">Provide your feedback and help us improve.</p>
                <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSexhaJwgHhgiBvrZbV6VpidEvDjqG5B1SiJo5RZoAoaLhKq-A/viewform?usp=sf_link"
                    title="Feedback link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="btn small">Provide Feedback</button>
                </a>
            </section>

            <a href="/signout">
                <button className="btn small">
                    Sign Out
                </button>
            </a>
        </main>
    );
};

export default GameHome;
