import React from 'react';
import './boosts.scss';

import Icon from "../../../../common/components/atoms/Icon/Icon";
// import BoostFactory from "./_sub/boostsFactory";
import BoostFollowX from "./_sub/boostsFollowX";
import BoostJoinDiscord from "./_sub/boostsJoinDiscord";
// import BoostsLike from "./_sub/boostsLike";
// import BoostsRetweet from "./_sub/boostsRetweet";
// import BoostsTweet from "./_sub/boostsTweet";
import BoostInvite from "./_sub/boostsInvite";
import BoostJoinTelegram from "./_sub/boostsJoinTelegram";
import BoostDailyCheckIn from "./_sub/boostDailyCheckIn";

const GameBoosts = (props) => {
    return (
        <div className="card">
            <h1>
                <Icon name="fire" />
                Boosts
            </h1>

            <p className="game-modal-sub-heading small">
                Your available balance is
                <b> ${props?.balance.toLocaleString() ?? 0}</b>
            </p>


            <BoostDailyCheckIn
                upgrades={props?.upgrades}
                buyUpgrade={props?.buyUpgrade}
            />

            <BoostJoinDiscord
                upgrades={props?.upgrades}
                buyUpgrade={props?.buyUpgrade}
            />

            <BoostJoinTelegram
                upgrades={props?.upgrades}
                buyUpgrade={props?.buyUpgrade}
            />

            <BoostInvite
                buyUpgrade={props?.buyUpgrade}
                upgrades={props?.upgrades}
                invite={props?.invite}
            />

            <BoostFollowX
                buyUpgrade={props?.buyUpgrade}
                upgrades={props?.upgrades}
            />

            {/*https://t.me/SlingShotDAOGC*/}

            {/*<BoostsLike />*/}

            {/*<BoostsRetweet />*/}

            {/*<BoostsTweet />*/}

            {/*<BoostInvite isLocked={false} />*/}
        </div>
    );
};

export default GameBoosts;
