import React from 'react';
import './leaderboard.scss';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import Icon from "../../../../common/components/atoms/Icon/Icon";
import LeaderboardMemberCard from "../../../../common/components/organisms/LeaderboardMemberCard/LeaderboardMemberCard";
import { useGetLeaderboard } from "../../../../data/useGetLeaderboard";
import arrow from "../../../../assets/images/down-arrow.webp";


const GameLeaderBoard = (props) => {
    const queryClient = useQueryClient();
    const {
        isPending,
        isError,
        data
    } = useGetLeaderboard(props.username);

    if (isPending) {
        // loading state
        return <></>;
    }

    if (isError) {
        // error page
        return <>
            <h1>
                <Icon name="trophy"/>
                Leaderboard
            </h1>

            <p className="game-modal-sub-heading small">Unknown error getting leaderboard</p>
        </>
    }


    const handleSaveUsername = (newName) => {
        props?.changeName(newName);

        // Invalidate the query to force a refetch
        queryClient.invalidateQueries({ queryKey: ['leaderboard', props.username] });
    };

    return (
        <>
            <h1>
                <Icon name="trophy"/>
                Leaderboard
            </h1>

            <p className="game-modal-sub-heading small">Work your way to the top!</p>

            <section className="leaderboard-cards-container">
                <div className="rank-container">
                    <p className="rank-header">Your Rank</p>

                    {data?.prev && (
                        <LeaderboardMemberCard
                            cardData={data.prev}
                            onSave={handleSaveUsername}
                            pos={data?.player[4] - 1?? ''}
                        />
                    )}

                    <LeaderboardMemberCard
                        canEdit={true}
                        cardData={data?.player}
                        onSave={handleSaveUsername}
                        pos={data?.player[4] ?? ''}
                    />

                    {data?.next && (
                        <LeaderboardMemberCard
                            cardData={data.next}
                            onSave={handleSaveUsername}
                            pos={data?.player[4] + 1?? ''}
                        />
                    )}
                </div>


                <div className="rank-container">
                    <p className="top-10-header">
                        <img
                            alt="Down arrow"
                            loading="lazy"
                            src={arrow}
                        />

                        TOP 10

                        <img
                            alt="Down arrow"
                            loading="lazy"
                            src={arrow}
                        />
                    </p>

                    {(data?.top10 ?? []).map((f, index) => (
                        <motion.div
                            key={index}
                            initial={{blur: '20px', opacity: 0, scale: 0.3, y: -20}}
                            animate={{blur: '0px', opacity: 1, scale: 1, y: 0}}
                            transition={{
                                delay: index * .1, // Staggered delay based on index
                                duration: .2,
                                ease: 'easeInOut',
                            }}
                        >
                            <LeaderboardMemberCard
                                cardData={f}
                                pos={index + 1}
                            />
                        </motion.div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default GameLeaderBoard;
