import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/styles/imports.scss';
import Home from './screens/home/home';
import Intro from "./screens/intro/intro";
import Game from './screens/game/game';
import Offline from "./screens/offline/offline";
import Signout from './screens/signout/signout';
import SignedOut from './screens/signed-out/signed-out';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GameBlocked from "./common/components/GameBlocked/GameBlocked";

const queryClient = new QueryClient()

const App = () => {
    const isLive = process.env.REACT_APP_LIVE === 'true';

    if (!isLive) {
        return (
            <QueryClientProvider client={queryClient}>
                <div className="main-container">
                    <GameBlocked />
                </div>
            </QueryClientProvider>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <div className="main-container">
                <Router>
                    <Routes>
                        <Route path="/" element={<Game />} />
                        <Route path="/intro" element={<Intro />} />
                        <Route path="/signout" element={<Signout />} />
                        <Route path="/signed-out" element={<SignedOut />} />
                        <Route path="/inactive" element={<Offline />} />
                    </Routes>
                </Router>
            </div>
        </QueryClientProvider>
    );
};

export default App;
