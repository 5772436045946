import React, { useState, useEffect, useRef } from 'react';
import './game.scss';
import useWebSocket, { ReadyState } from "react-use-websocket";
import GameLogic from "../../actions/game";
import Icon from "../../common/components/atoms/Icon/Icon";
import Modal from '../../common/components/molecules/Modal/Modal';
import AppNav from "../../common/components/organisms/AppNav/AppNav";
import SeededGame from "../../seedGame/seedGame";
import GameContainer from "./gameContainer/gameContainer";
import GameHelp from "./_sub/help/help";
import GameHome from "./_sub/home/home";
import GameBoosts from "./_sub/boosts/boosts";
import GameLeaderBoard from "./_sub/leaderboard/leaderboard";
import Invite from "./_sub/invite/invite";
import LevelUp from "../../common/components/organisms/LevelUp/LevelUp";
import OfflineEarnings from "./_sub/offline-earnings/offline-earnings";
import MotionFadeInUp from "../../common/components/_animation/MotionFadeInUp";
import Updates from "./_sub/updates/updates";


const Game = () => {
    // server websocket
    const {sendJsonMessage, lastJsonMessage, readyState} = useWebSocket(
        process.env.REACT_APP_WS_URL.replace('http', 'ws'),
        {
            share: true,
            shouldReconnect: () => true,
        },
    );

    // boosts
    const [isShowingBoosts, setShowingBoosts] = useState(false);
    const [currentBoostType, setCurrentBoostType] = useState(null);

    // content presentation logic
    const [isShowing, setIsShowing] = useState('');

    // UI data
    const [score, setScore] = useState(0); // users score
    const [offlineEarnings, setOfflineEarnings] = useState(0); // users offline earnings
    const [factoryScore, setFactoryScore] = useState(0); // factory score
    const [factoryName, setFactoryName] = useState(""); // factory score
    const [rank, setRank] = useState("Coming Soon") // rank
    const [ideas, setIdeas] = useState([]); // ideas belt
    const [studio, setStudio] = useState([]); // ideas belt
    const [studioScreenValue, setStudioScreenValue] = useState(0); // studio hopper
    const [upgrades, setUpgrades] = useState([]);
    const [seed, setSeed] = useState(null);
    const [invite, setInvite] = useState([0, 'username']);

    // UI state
    const [factoryLocked, setFactoryLocked] = useState(true); // ideas belt locked?
    const [studioLocked, setStudioLocked] = useState(true); // ideas belt locked?
    const [seeder, setSeeder] = useState(new SeededGame({})); // ideas belt locked?
    const [lastTickIdea, setLastTickIdea] = useState(Date.now());
    const [lastTickStudio, setLastTickStudio] = useState(Date.now());
    const [tickTime, setTickTime] = useState(0);
    const [turbo, setTurbo] = useState(false);

    useEffect(() => {
        setStudioScreenValue(seeder?.hopper?.length ?? 0);
    }, [seeder?.hopper?.length]);


    const prevAmount = useRef({ seed, factoryLocked, studioLocked }).current;

    useEffect(() => {
        setSeeder(newSeeder => {
            if (seed !== null) {
                console.debug('Seed changed', seed);
                newSeeder.seed = seed.s;
                newSeeder.tick = seed.t;
                newSeeder.studioSuccessChance = seed.cs;
                newSeeder.ideaSuccessChance = seed.ci;
                newSeeder.ideas = seed.l;
                return newSeeder;
            }
        })
    }, [seed]);

    useEffect(() => {
        if (seed !== null) {
            const interval = setInterval(() => {
                setTickTime(Date.now());
            }, 100);

            return () => {
                // console.log('Clearing');
                clearInterval(interval);
            };
        }


    }, [prevAmount.seed, seed, seeder]);

    const prevSeeder = useRef(seeder).current;
    useEffect(() => {
        if (tickTime !== 0 && seed !== null) {
            const scores = [0, 200, 10, 20, 30, 45, 55, 70, 80, 100];
            if (!factoryLocked && tickTime - seed.wi >= lastTickIdea) {

                setLastTickIdea(tickTime);
                // idea tick
                // console.log(seeder.seed);
                const i = seeder.nextIdea();
                if (i.t === '1') {
                    i.s = true;
                }
                i.scoreStart = 1;
                i.scoreEnd = i.s ? scores[i.t] : 0;
                i.onEnd = () => {
                    if (i.s) {
                        //console.log("SCORE", i);
                        setScore(score => {
                            //console.log(seeder.tick, seeder.seed);
                            //console.debug("+", [scores[i.t], score + scores[i.t]]);
                            return score + scores[i.t];
                        });
                        // add to hopper
                        seeder.addToHopper(i);
                    }
                };
                addIdea(i);

                setScore(score => {
                    // console.log(seeder.tick);
                    // console.debug("+", [1, score + 1]);
                    return score + 1;
                });
            }
            if (!studioLocked && tickTime - seed.ws >= lastTickStudio) {
                setLastTickStudio(tickTime);
                const i = seeder.nextStudio();
                if (i) {
                    if (i.t === '1') {
                        i.s = true;
                    }
                    i.scoreStart = 10;
                    const thisScore = Math.round(scores[i.t] * 10 * (1 + ((upgrades?.s?.[0] ?? 0)/10)));
                    i.scoreEnd = i.s ? thisScore : 0;
                    i.onEnd = () => {
                        if (i.s) {
                            // add score
                            setScore(score => {
                                // console.log(seeder.tick);
                                // console.debug("+", [scores[i.t], score + scores[i.t]]);
                                return score + thisScore;
                            });
                            // add to hopper
                        }
                    }
                    addIdea(i, setStudio);
                    setScore(score => {
                        // console.log(seeder.tick);
                        // console.debug("+", [10, score + 10, seeder.tick]);
                        return score + 10;
                    });
                }
            }
        }
    }, [tickTime, seed, factoryLocked, studioLocked, prevAmount.seed, seeder, lastTickStudio, upgrades, prevSeeder, lastTickIdea])

    // // start the game
    // useEffect(() => {
    //     setTimeout(() => {
    //         setSeed(123);
    //     }, 1000)
    // }, [])
    // useEffect(() => {
    //     setTimeout(() => {
    //         setStudioLocked(false);
    //     }, 4000)
    // }, [])

    //  boosts
    const handleBoostClick = (boostType) => {
        if (currentBoostType === boostType) {
            setShowingBoosts(false);
            setCurrentBoostType(null);  // Reset the boost type
        } else {
            setShowingBoosts(true);
            setCurrentBoostType(boostType);  // Update to the new boost type
        }
    };


    // Boosted animation
    const [animationQueue, setAnimationQueue] = useState([]);

    const handleBoosted = () => {
        setAnimationQueue((prevQueue) => [
            ...prevQueue,
            { id: Date.now(), type: "boostAnimation" }, // Unique ID with a timestamp
        ]);
    };

    const handleAnimationEnd = (id) => {
        setAnimationQueue((prevQueue) =>
            prevQueue.filter((item) => item.id !== id) // Remove item by ID
        );
    };

    // Toggle
    const toggleContent = (toShow) => {
        console.log('toggleContent', toShow);
        if (toShow === isShowing) {
            setIsShowing('')
        } else {
            setIsShowing(toShow);
        }
    };

    const addIdea = (idea, to=setIdeas) => {
        if (idea) {
            to(oldIdeas => [...oldIdeas, idea]);
        }
    }

    const removeIdea = (idea, from=setIdeas) => {
        from(oldItems => oldItems.filter(i => i.k !== idea.k));
    }

    const startFactory = () => {
        sendJsonMessage({event:'i', data:0});
    }

    const startStudio = () => {
        sendJsonMessage({event:'s', data:0});
    }

    const buyUpgrade = (upgrade) => {
        sendJsonMessage({event:upgrade, data:0});
    }

    const changeName = (name) => {
        sendJsonMessage({event:'n', data:name});
    }

    // process actions form the server
    useEffect(() => {
        GameLogic.processAction(
            {
                readyState,
                lastJsonMessage,
                setIsShowing,
                setScore,
                setFactoryLocked,
                setStudioLocked,
                addIdea,
                setFactoryScore,
                setFactoryName,
                setUpgrades,
                setSeed,
                setTurbo,
                setInvite,
                setOfflineEarnings,
            }
        );
    }, [lastJsonMessage, readyState]);


    // Detect move off tab
    const blurTimeout = useRef(null);

    useEffect(() => {
        const handleBlur = () => {
            // Start a timer when the window loses focus after Xsec
            blurTimeout.current = setTimeout(() => {
                window.location.replace('/inactive');
            }, 5 * 60 * 1000);
        };

        const handleFocus = () => {
            // Clear the timeout if the user comes back before the timer completes
            if (blurTimeout.current) {
                clearTimeout(blurTimeout.current);
                blurTimeout.current = null;
            }
        };

        window.addEventListener('blur', handleBlur);
        window.addEventListener('focus', handleFocus);

        return () => {
            // Cleanup event listeners and any pending timeouts
            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
            if (blurTimeout.current) {
                clearTimeout(blurTimeout.current);
            }
        };
    }, []);


    // Detect inactivity
    const inactivityTimeout = useRef(null); // To store the timeout ID

    const resetInactivityTimer = () => {
        if (inactivityTimeout.current) {
            clearTimeout(inactivityTimeout.current);
        }
        inactivityTimeout.current = setTimeout(() => {
            window.location.replace('/inactive');
        }, 10 * 60 * 1000);
    };

    useEffect(() => {
        const handleUserActivity = () => {
            resetInactivityTimer();
        };

        // Reset timer on any user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        // Set initial timeout
        resetInactivityTimer();

        return () => {
            // Cleanup event listeners and timeout
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            if (inactivityTimeout.current) {
                clearTimeout(inactivityTimeout.current);
            }
        };
    }, []);


    useEffect(() => {
        if (ReadyState.CLOSED) {
            setSeed(null);
            setSeeder(new SeededGame({}));
        }
    }, [readyState])

    if (readyState === ReadyState.CLOSED) {
        // Connection closed, awaiting reconnect.
        return (
            <div className="page-game">
                <Modal>
                    <h1>Disconnected</h1>
                    <p>
                        You have lost connection to the server, or the server is down for maintenance.
                        <br />
                        <br />
                        Don't worry, you will still be earning in the background.
                    </p>
                </Modal>
            </div>
        );
    }

    if (readyState === ReadyState.CONNECTING) {
        // pending. We can pre-load assets here
        return <div className="page-game"></div>
    }

    window.sendJsonMessage = sendJsonMessage;

    return (
        <div className="additional-clouds">
            <div className="page-game">
                {/*TODO @mike launchLocked needs integrating*/}
                <GameContainer
                    className={isShowing ? 'invisible' : ''}
                    score={score}
                    ideas={ideas}
                    studio={studio}
                    onEndItemAnimation={(i, studio = false) => removeIdea(i, studio ? setStudio : setIdeas)}
                    onFactoryStart={startFactory}
                    onStudioStart={startStudio}
                    factoryLocked={factoryLocked}
                    studioLocked={studioLocked}
                    launchLocked={true}
                    studioScreenValue={studioScreenValue}
                    buyUpgrade={buyUpgrade}
                    conveyorSpeed={5}
                    boostClicked={handleBoostClick}
                    toggleContent={(toShow) => toggleContent(toShow)}
                    turbo={turbo}
                />

                {/* help different to appNav */}
                {isShowing === 'help' && (
                    <Modal
                        header={
                            <span
                                className="close"
                                onMouseDown={() => setIsShowing('')}
                            >
                                Close
                                <Icon name="close"/>
                            </span>
                        }
                    >
                        <GameHelp factoryName={factoryName}/>
                    </Modal>
                )}

                {isShowing === 'updates' && (
                    <Modal
                        header={
                            <span
                                className="close"
                                onMouseDown={() => setIsShowing('')}
                            >
                                Close
                                <Icon name="close"/>
                            </span>
                        }
                    >
                        <Updates />
                    </Modal>
                )}


                {isShowing && (
                    <div
                        className={`
                            game-sub-content-container
                            ${isShowing === 'home' ? 'no-blur' : ''}`
                        }
                    >
                        {isShowing === 'home' && (
                            <MotionFadeInUp>
                                <GameHome
                                    showFactory={() => {
                                        setIsShowing('');
                                    }}
                                    toggleContent={(toShow) => toggleContent(toShow)}
                                    score={score}
                                    factoryName={factoryName}
                                    rank={rank}
                                    invite={invite}
                                />
                            </MotionFadeInUp>
                        )}

                        {isShowing === 'leaderboard' && (
                            <MotionFadeInUp>
                                <GameLeaderBoard
                                    username={invite[1]}
                                    changeName={changeName}
                                />
                            </MotionFadeInUp>
                        )}

                        {isShowing === 'invite' && (
                            <MotionFadeInUp>
                                <Invite onClose={() => toggleContent('home')}/>
                            </MotionFadeInUp>
                        )}

                        {isShowing === 'boosts' && (
                            <MotionFadeInUp>
                                <GameBoosts
                                    balance={score}
                                    upgrades={upgrades}
                                    factoryLocked={factoryLocked}
                                    buyUpgrade={(u) => buyUpgrade(u)}
                                    invite={invite}
                                />
                            </MotionFadeInUp>
                        )}

                        {isShowing === 'offline-earnings' && (
                            <MotionFadeInUp>
                                <OfflineEarnings
                                    earnings={offlineEarnings}
                                    onMouseDown={() => {
                                        setIsShowing('')
                                    }}
                                />
                            </MotionFadeInUp>
                        )}
                    </div>
                )}

                {animationQueue.map((item, index) => (
                    <aside
                        key={item.id}
                        className="boosted-animation"
                        onAnimationEnd={() => handleAnimationEnd(item.id)} // Trigger removal
                    >
                        <span>Factory upgraded</span>
                    </aside>
                ))}

                {/*{isShowingBoosts && (*/}
                    <LevelUp
                        boostType={currentBoostType}
                        className={(isShowingBoosts && isShowing !== 'help') ? 'active' : ''}
                        hasClickedBoost={() => handleBoosted()}
                        score={score}
                        upgrades={upgrades}
                        turboLevel={turbo}
                    />
                {/*)}*/}

                {(isShowing !== 'help' && isShowing !== 'updates') && (
                    <AppNav
                        clicked={(showContent) => toggleContent(showContent)}
                        score={score}
                        forceActive={isShowing}
                        username={invite[1]}
                        gameEnds={"TBD"}
                    />
                )}
            </div>
        </div>
    );
};

export default Game;
