import React from 'react';
import './nav-item.scss';

const NavItem = ({
    children,
    className = '',
    isActive,
     ...props
}) => {
    const componentName = 'nav-item';

    return (
        <div
            className={`
                ${componentName}
                ${isActive ? 'active' : ''}
                ${className}
            `}
            onMouseDown={props.clicked}
        >
            {children}
        </div>
    );
};

export default NavItem;

