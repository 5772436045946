import React, { useState, useEffect } from 'react';
import './level-up.scss';
import useWebSocket from "react-use-websocket";
import { AnimatePresence, motion } from 'framer-motion';
import { boostPrices } from "../../../../data/boostPrices";
import Icon from "../../atoms/Icon/Icon";
import TurboCta from "../../molecules/TurboCta/TurboCta";


const LevelUp = ({
    className,
    boostType,
    hasClickedBoost,
    turboLevel,
    ...props
}) => {
    const [isShowingInfo, setIsShowingInfo] = useState(false);
    const [helpText, setHelpText] = useState('');
    const [turboDuration, setTurboDuration] = useState(15 * 1000);

    // useEffect to react to changes in isShowingHelp
    useEffect(() => {
        setIsShowingInfo(false);
    }, [boostType]);

    const toggleShowInfo = (text = '') => {
        if (isShowingInfo && text === helpText) {
            setIsShowingInfo(false);
            setHelpText('');
        } else {
            setIsShowingInfo(true);
            setHelpText(text);
        }
    };

    const { sendJsonMessage } = useWebSocket(
        process.env.REACT_APP_WS_URL.replace('http', 'ws'),
        {
            share: true,
            shouldReconnect: () => true,
        },
    );

    //  help texts for each section and boost
    const helpTexts = {
        ideas: {
            generation: "Boost the generation speed of new ideas to increase potential earnings.",
            variation: "Increase the variation of the ideas to add new more valuable idea types.",
            success: "Improve the chance of ideas successfully passing through the detector."
        },
        studio: {
            quality: "The higher the quality the higher the score for games that are not cancelled.",
            turbo: "Increase the turbo duration.",
            success: "Increase the success rate of studio ideas reduce the number of ideas cancelled."
        },
        launch: {
            generation: "TODO",
            speed: "TODO",
            success: "TODO"
        }
    };

    const animationForBoosts = {
        initial: { y: 100 },
        animate: { y: 0 },
        transition: { duration: .15, ease: 'easeInOut' }
    }

    const ui0Cost = boostPrices.ui0[props?.upgrades?.i?.[0] ?? 0]; // generation
    const ui1Cost = boostPrices.ui1[props?.upgrades?.i?.[1] ?? 0]; // speed
    const ui2Cost = boostPrices.ui2[props?.upgrades?.i?.[2] ?? 0]; // success

    const [ideaTurboLevel, setIdeaTurboLevel] = useState(0);
    const [ideaTurboActive, setIdeaTurboActive] = useState(false);
    const [ideaProgress, setIdeaProgress] = useState(0);

    useEffect(() => {
        if (Array.isArray(turboLevel) && turboLevel.length > 2) {
            setIdeaTurboLevel(turboLevel[0]);
            setStudioTurboLevel(turboLevel[1]);
            setLaunchTurboLevel(turboLevel[2]);
        }
    }, [turboLevel])

    const handleTurboClicked = (turbo, setActive, setProgress, level) => {
        if (level === 3) {
            // @todo: Call server to start
            sendJsonMessage({ event: turbo, data: 0 })
            setActive(true);
            setProgress(0)
            // 100% instantly when turbo is activated
            setTimeout(() => {
                setProgress(100);
                setTimeout(() => {
                    let start = Date.now();
                    const interval = setInterval(() => {
                        const elapsed = Date.now() - start;
                        const newProgress = Math.max(0, 100 - (elapsed / turboDuration) * 100);
                        setProgress(newProgress);

                        if (elapsed >= turboDuration) {
                            clearInterval(interval);
                            setActive(false);
                        }
                    }, 10); // Update every 10ms for smoother animation
                }, 0);
            }, 0); // Jump to 100% instantly
        }
    }

    const handleIdeaTurboClicked = () => {
        handleTurboClicked(
            "t1",
            setIdeaTurboActive,
            setIdeaProgress,
            ideaTurboLevel
        );
    }

    const IdeasContent = () => (
        <motion.article
            className="boosts-available-container"
            {...animationForBoosts}
        >
            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.ideas.generation)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.ideas.generation ? "close" : "info"} />
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.i?.[0] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="cloud" />
                    Generation
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({ event: "ui0", data: 0 })}
                    }
                    disabled={ui0Cost > (props?.score ?? 0) || ui0Cost === 'MAX'}
                >
                    <Icon name="up-arrow" />
                    ${ui0Cost.toLocaleString()}
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.ideas.variation)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.ideas.variation ? "close" : "info"} />
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.i?.[1] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="dice" />
                    Variation
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({ event: "ui1", data: 0 })}
                    }
                    disabled={ui1Cost > (props?.score ?? 0) || ui1Cost === 'MAX'}
                >
                    <Icon name="up-arrow" />
                    ${ui1Cost.toLocaleString()}
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.ideas.success)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.ideas.success ? "close" : "info"} />
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.i?.[2] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="check" />
                    Success %
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({ event: "ui2", data: 0 })}
                    }
                    disabled={ui2Cost > (props?.score ?? 0) || ui2Cost === 'MAX'}
                >
                    <Icon name="up-arrow" />
                    ${ui2Cost.toLocaleString()}
                </button>
            </div>

            <TurboCta
                className="ideas"
                isActive={ideaTurboActive}
                level={ideaTurboLevel}
                onClick={() => handleIdeaTurboClicked()}
                progress={ideaProgress}
            />
        </motion.article>
    );


    const us0Cost = boostPrices.us0[props?.upgrades?.s?.[0] ?? 0]; // generation
    const us1Cost = boostPrices.us1[props?.upgrades?.s?.[1] ?? 0]; // speed
    const us2Cost = boostPrices.us2[props?.upgrades?.s?.[2] ?? 0]; // success

    const [studioTurboLevel, setStudioTurboLevel] = useState(0);
    const [studioTurboActive, setStudioTurboActive] = useState(false);
    const [studioProgress, setStudioProgress] = useState(0);

    const handleStudioTurboClicked = () => {
        handleTurboClicked(
            "t2",
            setStudioTurboActive,
            setStudioProgress,
            studioTurboLevel
        );
    }

    const StudioContent = () => (
        <motion.article
            className="boosts-available-container"
            {...animationForBoosts}
        >
            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.studio.quality)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.studio.quality ? "close" : "info"}/>
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.s?.[0] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="star"/>
                    Quality
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({event: "us0", data: 0})}
                    }
                    disabled={us0Cost > (props?.score ?? 0) || us0Cost === 'MAX'}
                >
                    ${us0Cost.toLocaleString()}
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.studio.turbo)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.studio.turbo ? "close" : "info"}/>
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.s?.[1] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="turbo"/>
                    Turbo
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({event: "us1", data: 0})}
                    }
                    disabled={us1Cost > (props?.score ?? 0) || us1Cost === 'MAX'}
                >
                    ${us1Cost.toLocaleString()}
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.studio.success)}
                >
                    <Icon name={isShowingInfo && helpText === helpTexts.studio.success ? "close" : "info"}/>
                </button>
                <h1>
                    <span>Lv. </span>
                    {props?.upgrades?.s?.[2] + 1 ?? ""}
                </h1>
                <p className="boost-effect">
                    <Icon name="check"/>
                    Success %
                </p>
                <button
                    className="btn-boost"
                    onClick={() => {
                        hasClickedBoost();
                        sendJsonMessage({event: "us2", data: 0})}
                    }
                    disabled={us2Cost > (props?.score ?? 0) || us2Cost === 'MAX'}
                >
                    ${us2Cost.toLocaleString()}
                </button>
            </div>

            <TurboCta
                className="studio"
                isActive={studioTurboActive}
                level={studioTurboLevel}
                onClick={() => handleStudioTurboClicked()}
                progress={studioProgress}
            />
        </motion.article>
    );


    const [launchTurboLevel, setLaunchTurboLevel] = useState(0);
    const [launchTurboActive, setLaunchTurboActive] = useState(false);
    const [launchProgress, setLaunchProgress] = useState(0);

    const handleLaunchTurboClicked = () => {
        handleTurboClicked(
            "t3",
            setLaunchTurboActive,
            setLaunchProgress,
            launchTurboLevel
        );
    }

    const LaunchContent = () => (
        <motion.article
            className="boosts-available-container"
            {...animationForBoosts}
        >
            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.launch.generation)}
                >
                    <Icon name="info"/>
                </button>
                <h1>
                    <span>Lv. </span>
                    999
                </h1>
                <p className="boost-effect">
                    <Icon name="cloud"/>
                    Generation
                </p>
                <button
                    className="btn-boost"
                    onClick={() => sendJsonMessage({event: "ui2", data: 0})}
                >
                    $99,000,000
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.launch.speed)}
                >
                    <Icon name="info"/>
                </button>
                <h1>
                    <span>Lv. </span>
                    999
                </h1>
                <p className="boost-effect">
                    <Icon name="cog"/>
                    Belt Speed
                </p>
                <button
                    className="btn-boost"
                    onClick={() => sendJsonMessage({event: "ui2", data: 0})}
                >
                    $99,000,000
                </button>
            </div>

            <div className="boost-info">
                <button
                    className="btn no-style info-cta"
                    onClick={() => toggleShowInfo(helpTexts.launch.success)}
                >
                    <Icon name="info"/>
                </button>
                <h1>
                    <span>Lv. </span>
                    999
                </h1>
                <p className="boost-effect">
                    <Icon name="check"/>
                    Success %
                </p>
                <button
                    className="btn-boost"
                    onClick={() => sendJsonMessage({event: "ui2", data: 0})}
                >
                    $99,000,000
                </button>
            </div>

            <TurboCta
                className="launch"
                isActive={launchTurboActive}
                level={launchTurboLevel}
                onClick={() => handleLaunchTurboClicked()}
                progress={launchProgress}
            />
        </motion.article>
    );

    return (
        <div
            className={`
                level-up
                ${className}
            `}
        >
            <AnimatePresence>
                {isShowingInfo && (
                    <motion.aside
                        className={`
                            boost-info-container
                            ${boostType === 'ideas' ? 'ideas' : ''}
                            ${boostType === 'studio' ? 'studio' : ''}
                            ${boostType === 'launch' ? 'launch' : ''}
                        `}
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0 }}
                        transition={{ duration: .15, ease: 'easeInOut' }}
                    >
                        <button
                            className="btn no-style info-close-cta"
                            onClick={() => {
                                setIsShowingInfo(false);
                            }}
                        >
                            <Icon name="close" />
                        </button>
                        <p>{helpText}</p>
                    </motion.aside>
                )}
            </AnimatePresence>


            <section
                className={`
                    nav-container
                    ${boostType}
                `}
            >
                {boostType === 'ideas' ? IdeasContent() :
                    boostType === 'studio' ? StudioContent() :
                        boostType === 'launch' && LaunchContent()}
            </section>
        </div>
    );
};

export default LevelUp;
