import React from "react";
import "./turbo-cta.scss";
import Icon from "../../atoms/Icon/Icon";


const TurboCta = ({
    className = '',
    disabled = false,
    isActive = false,
    level = 0,
    progress = 0,
    onClick,
}) => {
    const componentName = "turbo-cta";

    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    }

    return (
        <div
            className={`
                ${componentName}
                ${className}
            `}
        >
            <div className="main-container">
                <ul className="turbo-guage">
                    <li className={level >= 3 ? 'active' : ''}>
                        <span className="accessible">3rd indicator</span>
                    </li>
                    <li className={level >= 2 ? 'active' : ''}>
                        <span className="accessible">2nd indicator</span>
                    </li>
                    <li className={level >= 1 ? 'active' : ''}>
                        <span className="accessible">1st indicator</span>
                    </li>
                </ul>

                {isActive ? (
                    <span>LFG!</span>
                ) : (
                    <span>Turbo</span>
                )}

                <button
                    className={`
                        boost-energy-cta
                        ${level < 3 ? 'static' : ''}
                        ${level === 4 && progress < 100 && progress > 90 ? 'boom' : ''}
                    `}
                    disabled={isActive || level < 3}
                    onClick={() => handleClick()}
                >
                    <span>
                        <Icon name="rocket" />
                        <span className="accessible">Activate Turbo</span>
                    </span>
                </button>
            </div>

            <div
                className={`
                    activated-bar
                `}
                style={{
                    backgroundPositionY: `${progress}%`, // Dynamically adjust background
                }}
            >
                <span className="accessible">Progress bar</span>
            </div>
        </div>
    );
};

export default TurboCta;
